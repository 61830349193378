<template>
	<div class="page">
		<div class="w1000">
			<topHeader @goBack="sysgoback()"></topHeader>
			<div class="top-col">
				<div class="line"></div>
				<span>单词本列表</span>
				<img @click="handleaddbook" class="add-btn" src="@/assets/static/add_word_book_icon.png" alt=""/>
			</div>

			<div class="book-list">
				<div class="book-item" @click="toWordBookDetail(item)" v-for="(item, index) in dataList" :key="index">
					<img src="@/assets/static/danciben.png" alt=""/>
					<div class="book-title">{{item.title}}</div>
					<div class="book-num">共{{item.wordNum}}单词</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState,} from "vuex";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
		},
		data() {
			return {
				keyword:"",
				dataList:[],//
			};
		},
		computed: {
			...mapState(["userInfo"]),
		},
		created() {
			//数据
			this.userCollectBooks()
		},
		methods: {
			//增加
			handleaddbook(){
				var _this = this
				this.$prompt('请输入单词本名称', '创建单词本', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				}).then(({ value }) => {
				    _this.keyword = value
				    //创建
				    _this.handlecreate(1)
				}).catch(() => {
				    this.$message({
				       type: 'info',
				       message: '取消输入'
				    });       
				});
			},
			//创建
			handlecreate(type){
				var _this = this
				if(this.keyword){
					var params = {
						title: this.keyword,
						userUuid: this.userInfo.uuid
					}
					this.$http.post('app/word/userCollectBooks/save', params).then(function(res) {
						//数据处理
						if (res.code == 200) {
							_this.isShowAdd= false
							_this.keyword = ""
							if(type==1){
								_this.$message.success("创建成功！")
								//刷新
								_this.userCollectBooks()
							}else{
								_this.userCollectBooks()
							}
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				}else{
					this.$message.error("请输入名称")
				}
			},
			//数据
			userCollectBooks(){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid,
					currentPage:1,
					pageSize:888
				}
				this.$http.post('app/word/userCollectBooks/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.dataList = res.data
						if(res.data.length<=0){
							_this.keyword = "默认单词本"
							//创建
							_this.handlecreate(2)
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//详情
			toWordBookDetail(item) {
				this.$router.push({
					path: '/wordBooksDetail',
					query:{
						uid:item.id,
						name:item.title
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";

	.book-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;

		.book-item {
			width: 235px;
			height: 335px;
			border-radius: 16px;
			background: #fff;
			box-shadow: 0px 3px 15.5px 0px #0000001a;
			margin: 20px 20px 0 0;
			padding: 16px;
			cursor: pointer;

			&:hover {
				border: #e2e2e2 1px solid;
			}

			img {
				width: 203px;
				height: 220px;
				border-radius: 8px;
			}

			.book-title {
				color: #222222;
				margin-top: 16px;
				@include text-line(1);
				font-size: 16px;
				font-weight: bold;
			}

			.book-num {
				color: #666666;
				font-size: 14px;
				margin-top: 8px;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}

	.top-col {
		font-size: 20px;
		font-weight: bold;
		display: flex;
		align-items: center;
		color: #5c3704;
		margin-top: 40px;

		.line {
			height: 14px;
			width: 2px;
			border-radius: 2px;
			background: #51d9a5;
			margin-right: 16px;
		}

		.add-btn {
			margin-left: auto;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				opacity: 0.8;
			}
		}
	}
</style>